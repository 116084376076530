<template>
  <div class="container">
    <loading :active.sync="isLoading" :can-cancel="false" :is-full-page="fullPage"></loading>
    <div class="card">
      <div class="card-header">
        <h6 class="mb-0">{{ translations.tcPreviewPDFFile }}</h6>
      </div>
      <div class="card-body py-3 fifty">
        <p class="text-sm mb-0"></p>
        <div>
          <pdfvuer
            :src="pdfdata"
            v-for="i in numPages"
            :key="i"
            :id="i"
            :page="i"
            :scale.sync="scale"
            style="width: 100%; margin: 20px auto"
          >
            <template slot="loading">{{ translations.tcLoadingContentHere }}</template>
          </pdfvuer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import constantData from '@/json/data.json'
import Loading from 'vue-loading-overlay'
import pdfvuer from 'pdfvuer'
import { translationMixin } from '@/mixins/translationMixin'
import 'vue-loading-overlay/dist/vue-loading.css'

export default {
  name: 'magazine-pdfpreview',
  mixins: [translationMixin],
  components: {
    pdfvuer: pdfvuer,
    Loading,
  },
  beforeRouteEnter(to, from, next) {
    if (Object.keys(to.query).length !== 0) {
      // if the url has query (?query)
      next((vm) => {
        console.error(vm.$route.query)
      })
    }
    // next()
  },
  data() {
    return {
      pdfdata: undefined,
      numPages: null,
      scale: 'page-width',
      isLoading: true,
      fullPage: true,
      translations: {},
    }
  },
  async created() {
    await Promise.all([this.getViewTranslations(), this.getPdf()])
  },
  methods: {
    async getPdf() {
      this.isLoading = true
      let filename2 = this.$route.query.filename
      this.pdfdata = pdfvuer.createLoadingTask(constantData.magazine_prefix + filename2)
      await this.pdfdata.then((pdf) => {
        this.numPages = pdf.numPages
        this.isLoading = false
      })
    },
  },
    computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
  },
}
</script>

<style>
.fifty {
  height: 80vh;
  overflow: auto;
  width: 100%;
  margin: 20px auto;
}
</style>
